const i18nService = {
  defaultLanguage: "en",

  languages: [
    {
      lang: "en",
      name: "English",
      flag:
        process.env.VUE_APP_BASE_URL +
        process.env.BASE_URL +
        "media/svg/flags/226-united-states.svg"
    },
    {
      lang: "cs",
      name: "Czech",
      flag:
        process.env.VUE_APP_BASE_URL +
        process.env.BASE_URL +
        "media/svg/flags/149-czech-republic.svg"
    }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    const storedLanguage = localStorage.getItem("language");
    let lang;

    if (storedLanguage) {
      lang = storedLanguage;
    } else {
      const browserLang = navigator.language || navigator.userLanguage;
      lang =
        browserLang && browserLang.toLowerCase().startsWith("cs") ? "cs" : "en";
    }

    return lang;
  }
};

export default i18nService;
