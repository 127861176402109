<template>
  <ul class="menu-nav">
    <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">{{ $t("dashboard.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="isOwner"
        to="/projects"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-website"></i>
          <span class="menu-text">{{ $t("projects.title") }}</span>
        </a>
      </li>
    </router-link>
    <li v-if="isAnalytics" class="menu-section">
      <h4 class="menu-text">{{ $t("analytics.title") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      v-if="isAnalytics"
      to="/searches-without-results"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-graph"></i>
          <span class="menu-text">{{
            $t("analytics.searches_without_results.title")
          }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isAnalytics"
      to="/trending-items"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-graph"></i>
          <span class="menu-text">{{
            $t("analytics.trending_items.title")
          }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isAnalytics"
      to="/trending-searches"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-graph"></i>
          <span class="menu-text">{{
            $t("analytics.trending_searches.title")
          }}</span>
        </a>
      </li>
    </router-link>
    <li v-if="isSearchSettings" class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.SEARCH_SETTINGS") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="isSearchSettings"
      to="/synonyms"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-cube-1"></i>
          <span class="menu-text">{{ $t("synonyms.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isSearchSettings"
      to="/stopwords"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-cancel"></i>
          <span class="menu-text">{{ $t("stopwords.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isSearchSettings"
      to="/curations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-visible"></i>
          <span class="menu-text">{{ $t("curration.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isSearchSettings"
      to="/boost-manager"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-7"></i>
          <span class="menu-text">{{ $t("boost_manager.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isSearchSettings"
      to="/fixit"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-refresh"></i>
          <span class="menu-text">{{ $t("fixit.title") }}</span>
        </a>
      </li>
    </router-link>

    <li v-if="isData" class="menu-section">
      <h4 class="menu-text">Data</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      v-if="isData"
      to="/collections"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-files-and-folders"></i>
          <span class="menu-text">Collections</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isData"
      to="/phrases"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-3"></i>
          <span class="menu-text">Phrases</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isData"
      to="/schema"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-menu-3"></i>
          <span class="menu-text">Schema</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isData && currentProject.source !== 'mastershop-api'"
      to="/import"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-upload"></i>
          <span class="menu-text">Import</span>
        </a>
      </li>
    </router-link>

    <li v-if="isOwner" class="menu-section">
      <h4 class="menu-text">My project</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      v-if="isOwner"
      to="/project/"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-website"></i>
          <span class="menu-text">Project</span>
        </a>
      </li>
    </router-link>
    <li v-if="isAccessManagement" class="menu-section">
      <h4 class="menu-text">ACCESS</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <invite_dialog />
    <router-link
      v-if="isAccessManagement"
      to="/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-users"></i>
          <span class="menu-text">Users</span>
        </a>
      </li>
    </router-link>
    <li v-if="isSuperAdmin" class="menu-section">
      <h4 class="menu-text">SUPER ADMIN</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      v-if="isSuperAdmin"
      to="/api-logs"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-clipboard"></i>
          <span class="menu-text">API LOGS</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isSuperAdmin"
      to="/all-users"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-users"></i>
          <span class="menu-text">All users</span>
        </a>
      </li>
    </router-link>
    <li v-if="isDeveloper" class="menu-section">
      <h4 class="menu-text">DEVELOPER</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="isDeveloper"
      to="/relevance-tuning"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-7"></i>
          <span class="menu-text">Relevance Tuning</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/check-items"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-resize"></i>
          <span class="menu-text">Check Items</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/chars-filter"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-cube-1"></i>
          <span class="menu-text">{{ $t("chars_filter.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/query-tester"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-search"></i>
          <span class="menu-text">{{ $t("query_tester.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/documents"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-3"></i>
          <span class="menu-text">{{ $t("documents.title") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="isDeveloper"
      to="/upsearch-documents"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-3"></i>
          <span class="menu-text">{{ $t("upsearch_documents.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/credentials/"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-website"></i>
          <span class="menu-text">{{ $t("credentials.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/frontapi/endpoints/"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-website"></i>
          <span class="menu-text">{{ $t("frontapi.endpoints.title") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/result-settings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-ui"></i>
          <span class="menu-text">Result Settings</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/compare-results"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-resize"></i>
          <span class="menu-text">Compare results</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="isDeveloper"
      to="/analyze"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-resize"></i>
          <span class="menu-text">Term analyze</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import invite_dialog from "@/view/pages/users/invite_dialog";
import { mapGetters } from "vuex";

export default {
  components: { invite_dialog },
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  computed: {
    isAnalytics() {
      return (
        this.isOwner ||
        this.currentUserPersonalInfo.user_groups.some(item => item.id == 1)
      );
    },
    isSearchSettings() {
      return (
        this.isOwner ||
        this.currentUserPersonalInfo.user_groups.some(item => item.id == 2)
      );
    },
    isData() {
      return (
        this.isOwner ||
        this.currentUserPersonalInfo.user_groups.some(item => item.id == 3)
      );
    },
    isDeveloper() {
      return (
        this.isOwner ||
        this.currentUserPersonalInfo.user_groups.some(item => item.id == 4)
      );
    },
    isOwner() {
      return (
        this.currentUserPersonalInfo.is_admin ||
        this.currentUserPersonalInfo.is_owner
      );
    },
    isSuperAdmin() {
      return this.currentUserPersonalInfo.is_admin;
    },
    isAccessManagement() {
      return (
        this.isOwner ||
        this.currentUserPersonalInfo.user_groups.some(item => item.id == 5)
      );
    },
    ...mapGetters(["currentProject", "currentUserPersonalInfo"])
  }
};
</script>
