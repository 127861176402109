var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("dashboard.title")))])])])]}}])}),(_vm.isOwner)?_c('router-link',{attrs:{"to":"/projects"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("projects.title")))])])])]}}],null,false,2926809189)}):_vm._e(),(_vm.isAnalytics)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("analytics.title")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.isAnalytics)?_c('router-link',{attrs:{"to":"/searches-without-results"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-graph"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("analytics.searches_without_results.title")))])])])]}}],null,false,3681733826)}):_vm._e(),(_vm.isAnalytics)?_c('router-link',{attrs:{"to":"/trending-items"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-graph"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("analytics.trending_items.title")))])])])]}}],null,false,590258332)}):_vm._e(),(_vm.isAnalytics)?_c('router-link',{attrs:{"to":"/trending-searches"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-graph"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("analytics.trending_searches.title")))])])])]}}],null,false,3486655842)}):_vm._e(),(_vm.isSearchSettings)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SEARCH_SETTINGS")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.isSearchSettings)?_c('router-link',{attrs:{"to":"/synonyms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cube-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("synonyms.title")))])])])]}}],null,false,4086874935)}):_vm._e(),(_vm.isSearchSettings)?_c('router-link',{attrs:{"to":"/stopwords"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-cancel"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("stopwords.title")))])])])]}}],null,false,3536532969)}):_vm._e(),(_vm.isSearchSettings)?_c('router-link',{attrs:{"to":"/curations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-visible"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("curration.title")))])])])]}}],null,false,1284719823)}):_vm._e(),(_vm.isSearchSettings)?_c('router-link',{attrs:{"to":"/boost-manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-7"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("boost_manager.title")))])])])]}}],null,false,646562364)}):_vm._e(),(_vm.isSearchSettings)?_c('router-link',{attrs:{"to":"/fixit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-refresh"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("fixit.title")))])])])]}}],null,false,2164768495)}):_vm._e(),(_vm.isData)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Data")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.isData)?_c('router-link',{attrs:{"to":"/collections"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-files-and-folders"}),_c('span',{staticClass:"menu-text"},[_vm._v("Collections")])])])]}}],null,false,744562004)}):_vm._e(),(_vm.isData)?_c('router-link',{attrs:{"to":"/phrases"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Phrases")])])])]}}],null,false,1061331341)}):_vm._e(),(_vm.isData)?_c('router-link',{attrs:{"to":"/schema"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-menu-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Schema")])])])]}}],null,false,1477810262)}):_vm._e(),(_vm.isData && _vm.currentProject.source !== 'mastershop-api')?_c('router-link',{attrs:{"to":"/import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-upload"}),_c('span',{staticClass:"menu-text"},[_vm._v("Import")])])])]}}],null,false,3726008294)}):_vm._e(),(_vm.isOwner)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("My project")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.isOwner)?_c('router-link',{attrs:{"to":"/project/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v("Project")])])])]}}],null,false,1871285028)}):_vm._e(),(_vm.isAccessManagement)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("ACCESS")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),_c('invite_dialog'),(_vm.isAccessManagement)?_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text"},[_vm._v("Users")])])])]}}],null,false,2603463512)}):_vm._e(),(_vm.isSuperAdmin)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("SUPER ADMIN")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.isSuperAdmin)?_c('router-link',{attrs:{"to":"/api-logs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-clipboard"}),_c('span',{staticClass:"menu-text"},[_vm._v("API LOGS")])])])]}}],null,false,3386901019)}):_vm._e(),(_vm.isSuperAdmin)?_c('router-link',{attrs:{"to":"/all-users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text"},[_vm._v("All users")])])])]}}],null,false,1158646905)}):_vm._e(),(_vm.isDeveloper)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("DEVELOPER")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/relevance-tuning"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-7"}),_c('span',{staticClass:"menu-text"},[_vm._v("Relevance Tuning")])])])]}}],null,false,1197159433)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/check-items"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-resize"}),_c('span',{staticClass:"menu-text"},[_vm._v("Check Items")])])])]}}],null,false,2126064440)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/chars-filter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cube-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("chars_filter.title")))])])])]}}],null,false,329504449)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/query-tester"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-search"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("query_tester.title")))])])])]}}],null,false,2224291440)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/documents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("documents.title")))])])])]}}],null,false,3724377933)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/upsearch-documents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("upsearch_documents.title")))])])])]}}],null,false,2151029977)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/credentials/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("credentials.title")))])])])]}}],null,false,2546967899)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/frontapi/endpoints/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("frontapi.endpoints.title")))])])])]}}],null,false,1789559364)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/result-settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-ui"}),_c('span',{staticClass:"menu-text"},[_vm._v("Result Settings")])])])]}}],null,false,3346449498)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/compare-results"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-resize"}),_c('span',{staticClass:"menu-text"},[_vm._v("Compare results")])])])]}}],null,false,3794870725)}):_vm._e(),(_vm.isDeveloper)?_c('router-link',{attrs:{"to":"/analyze"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-resize"}),_c('span',{staticClass:"menu-text"},[_vm._v("Term analyze")])])])]}}],null,false,542570898)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }